"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomerSourceModel = /** @class */ (function () {
    function CustomerSourceModel($http, APPURL) {
        this.getCustomerSourceByID = function (id) {
            return this.$http.get(this.URLS.FETCH + '/' + id).then(this.cacheResults);
        };
        this.getCustomerSources = function () {
            return this.$http.get(this.URLS.FETCH).then(this.cacheResults);
        };
        this.createCustomerSource = function (request) {
            return this.$http.post(this.URLS.FETCH, request).then(this.cacheResults);
        };
        this.removeCustomerSource = function (cs) {
            return this.$http.delete(this.URLS.FETCH + '/' + cs.id).then(this.cacheResults);
        };
        this.$http = $http;
        this.URLS = {
            FETCH: APPURL + '/api/v1/marketing/customer-sources',
            UPDATE: APPURL + '/api/v1/marketing/customer-sources',
            CREATE: APPURL + '/api/v1/marketing/customer-sources'
        },
            this.data = [];
    }
    CustomerSourceModel.prototype.extract = function (result) {
        return result.data;
    };
    CustomerSourceModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    return CustomerSourceModel;
}());
CustomerSourceModel.$inject = ['$http', 'APPURL'];
exports.default = CustomerSourceModel;
