"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var queryString_1 = require("../helpers/queryString");
var CancelationModel = /** @class */ (function () {
    function CancelationModel($http, APPURL) {
        var _this = this;
        this.getCancelations = function (data) {
            if (data === void 0) { data = null; }
            if (data !== null) {
                return _this.$http.get(_this.URLS.FETCH + '?' + (0, queryString_1.toQueryString)(data)).then(_this.cacheResults);
            }
            return _this.$http.get(_this.URLS.FETCH).then(_this.cacheResults);
        };
        this.$http = $http;
        this.APPURL = APPURL;
        this.URLS = {
            FETCH: APPURL + '/api/v1/cancelations',
            UPDATE: APPURL + '/api/v1/cancelations',
            CREATE: APPURL + '/api/v1/cancelations'
        };
        this.data = [];
        this.cacheResults = this.cacheResults.bind(this);
        this.extract = this.extract.bind(this);
    }
    CancelationModel.prototype.extract = function (result) {
        return result.data;
    };
    CancelationModel.prototype.cacheResults = function (result) {
        this.data = this.extract(result);
        return this.data;
    };
    return CancelationModel;
}());
CancelationModel.$inject = ['$http', 'APPURL'];
exports.default = CancelationModel;
